<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 40 40"
    fill="none"
    @click="$emit('click')"
  >
    <path
      d="M31.6666 21.6673H21.6666V31.6673H18.3333V21.6673H8.33325V18.334H18.3333V8.33398H21.6666V18.334H31.6666V21.6673Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcPlus',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
