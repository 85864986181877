<template>
  <button
    type="button"
    :disabled="disabled"
    :name="name"
    class="flex flex-row"
    :class="[
      'google-button',
      'google-font',
      'transition',
      'transform',
      'focus:outline-none',
      'active:scale-95',
      { blue: blue, 'white px-2 text-google-blue': white },
    ]"
    @click="click"
  >
    <div
      class="bg-white my-auto h-full flex flex-col justify-center"
      :class="{ 'rounded-full': white }"
    >
      <img
        :src="googleButton"
        :alt="name"
        class="mx-3 my-auto"
      />
    </div>
    <span class="text-sm mx-3 my-auto">
      <slot />
    </span>
  </button>
</template>

<script>
import googleConfig from '@/configs/google-client'

export default {
  name: 'GoogleAllowAccessButton',
  metaInfo() {
    return {
      script: [{ src: 'https://accounts.google.com/gsi/client', async: true }],
    }
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'google',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    blue: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
    accessScope: {
      type: String,
      required: true,
    },
    oneTap: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      client: null,
    }
  },
  computed: {
    googleButton() {
      if (this.type === 'google' || !this.type) {
        return '/content/images/google-g-icon.svg'
      } else if (this.type === 'google_ads') {
        return '/content/images/google_ads.svg'
      }
      return []
    },
  },
  async mounted() {
    await this.initGoogle()
  },
  methods: {
    async initGoogle() {
      window.onGoogleLibraryLoad = () => {
        this.client = window.google.accounts.oauth2.initCodeClient({
          client_id: googleConfig.client_id,
          scope: this.accessScope,
          ux_mode: 'popup',
          callback: response => this.allowedAccessCallback(response),
        })
      }
    },
    click() {
      this.client.requestCode()
    },
    allowedAccessCallback(response) {
      console.log('VERIFY TOKEN')
      this.$emit('verify-token', response)
    },
  },
}
</script>
<style scoped lang="scss">
.google-button {
  @apply text-left bg-white;
  width: fit-content;
  height: 42px;
  box-shadow: 0 11px 24px rgba(0, 0, 0, 0.05);

  transition:
    transform 150ms ease,
    box-shadow 150ms ease;

  &:focus,
  &:active {
    transform: scale(0.97);
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.07);
  }

  img {
    height: 18px;
    width: 18px;
  }

  &.blue {
    @apply bg-google-blue text-white border border-google-blue;
  }
}

.white {
  border: $neutral-100 solid 1px !important;
  border-radius: 9999px !important;
}
</style>
