<template>
  <div>
    <div class="p-8 flex flex-row justify-between">
      <div class="flex flex-col space-y-4">
        <p class="text-sm text-bb-text-default">Connect <span class="font-bold">Amazon Selling Partner</span></p>
        <search-input
          v-model="selectedMarketplace"
          input-id="marketplace"
          input-name="marketplace"
          :label="null"
          placeholder="Location"
          :options="marketplaces"
        />
      </div>
      <AmazonSellingPartnerChannel
        :marketplace="selectedMarketplace"
        class="mt-0 mb-auto"
      />
    </div>
    <div class="p-8 flex flex-row justify-between">
      <p class="text-sm text-bb-text-default">Connect <span class="font-bold">Amazon Advertising</span></p>
      <AmazonAdvertisingChannel
        :marketplace="selectedMarketplace"
        class="mt-0 mb-auto"
      />
    </div>
  </div>
</template>

<script>
import AmazonSellingPartnerChannel from './amazon/AmazonSellingPartnerChannel.vue'
import AmazonAdvertisingChannel from './amazon/AmazonAdvertisingChannel.vue'
import SearchInput from '@/components/input/brightbid/SearchInput.vue'

export default {
  name: 'AmazonIntegration',
  components: {
    AmazonSellingPartnerChannel,
    AmazonAdvertisingChannel,
    SearchInput,
  },
  data() {
    return {
      marketplaces: [
        { region: 'Europe', label: 'United Kingdom', value: 'united_kingdom', url: 'sellercentral-europe.amazon.com' },
        { region: 'Europe', label: 'Germany', value: 'germany', url: 'sellercentral-europe.amazon.com' },
        { region: 'Europe', label: 'France', value: 'france', url: 'sellercentral-europe.amazon.com' },
        { region: 'Europe', label: 'Italy', value: 'italy', url: 'sellercentral-europe.amazon.com' },
        { region: 'Europe', label: 'Spain', value: 'spain', url: 'sellercentral-europe.amazon.com' },
        { region: 'Europe', label: 'Netherlands', value: 'netherlands', url: 'sellercentral.amazon.nl' },
      ],
      selectedMarketplace: null,
    }
  },
}
</script>

<style scoped></style>
