<template>
  <div class="flex justify-between">
    <div
      v-if="loadingIntegration || loadError"
      class="text-danger"
    />
    <loader-ellipsis
      v-if="loadingIntegration || isIntegrating"
      class="text-primary"
    />
    <div
      v-else-if="isIntegrated"
      class="flex items-center space-x-2"
    >
      <strong>Amazon Advertising id:&nbsp;</strong> {{ integration.advertising_id }}
      <ic-check
        :size="18"
        class="text-success"
      />
    </div>
    <div
      v-else
      class="flex flex-col"
    >
      <amazon-button @click="authorise" />
      <p
        v-if="missingMarketplace"
        class="text-bb-dull-red p1"
      >
        Please select a location
      </p>
    </div>
  </div>
</template>

<script>
import { buildURLQuery } from '@/utils/util'
import IcCheck from 'vue-material-design-icons/CheckCircle'
import LoaderEllipsis from '@/components/loader/EllipsisLoader'
import Toast from '@/components/shared/Toast'
import AmazonButton from '@/components/btn/AmazonButton'

export default {
  name: 'AmazonSellingPartnerChannel',
  components: { AmazonButton, LoaderEllipsis, IcCheck },
  inject: ['context'],
  props: {
    marketplace: {
      type: Object,
      default: () => ({
        region: '',
        country: '',
        value: '',
        url: '',
      }),
    },
  },
  data() {
    return {
      showInfo: false,
      integration: null,
      loadError: null,
      missingMarketplace: false,
      loadingIntegration: false,
      isIntegrating: false,
      siteID: this.context.siteID,
      organizationID: this.context.organizationID,
    }
  },
  computed: {
    isIntegrated() {
      return this.integration?.integrated
    },
    cardStatus() {
      return this.isIntegrated ? 'success' : 'default'
    },
    authResponseParams() {
      const { state, selling_partner_id, mws_auth_token, spapi_oauth_code } = this.$route.query
      return { state, selling_partner_id, mws_auth_token, spapi_oauth_code }
    },
    info() {
      if (!this.integration)
        return {
          market_place: 'N/A',
          g_ads_campaign: 'N/A',
          sync_status: 'N/A',
          paused: 'N/A',
        }
      return {
        market_place: this.integration.market_place_id || 'N/A',
        g_ads_campaign: this.integration.g_ads_campaign_id || 'N/A',
        sync_status: this.integration.last_sync || 'N/A',
        paused: this.integration.paused ? 'Paused' : 'Active',
      }
    },
  },
  mounted() {
    this.loadIntegration()
    this.handleAuthResponse()
  },
  methods: {
    authorise() {
      if (!this.marketplace) {
        this.missingMarketplace = true
        return
      }
      this.isIntegrating = true
      const state = this.setSellingPartnerAuthState()
      const authUrl = this.buildSellingPartnerAuthUrl(state)
      window.location = authUrl
    },
    buildSellingPartnerAuthUrl(state) {
      let params = {
        application_id: process.env.VUE_APP_SP_APP_ID,
        redirect_uri: `${window.location.origin}/onboarding`,
        state,
      }

      return `https://${this.marketplace?.url}/apps/authorize/consent?${buildURLQuery(params)}`
    },
    setSellingPartnerAuthState() {
      const state = JSON.stringify({
        c: this.marketplace.label.toLowerCase(),
        s: this.siteID,
        d: new Date(),
      })
      localStorage.setItem('SP_STATE', state)
      return state
    },
    removeSellingPartnerAuthState() {
      localStorage.removeItem('SP_STATE')
    },
    readSellingPartnerAuthState() {
      return localStorage.getItem('SP_STATE')
    },
    async handleAuthResponse() {
      const localState = this.readSellingPartnerAuthState()

      if (localState && localState === this.authResponseParams.state) {
        this.isIntegrating = true
        try {
          await this.$http.post(`commmon/site/${this.siteID}/integration/amazon/seller/authorize`, {
            selling_partner_id: this.authResponseParams.selling_partner_id,
            auth_code: this.authResponseParams.spapi_oauth_code,
            country: JSON.parse(localState).c,
          })
          this.loadIntegration()
        } catch (e) {
          this.$toast.error({
            component: Toast,
            props: {
              title: 'Error',
              message: 'Could not integrate Amazon Selling Partner',
              type: 'error',
            },
          })
        } finally {
          this.isIntegrating = false
        }
      }
      this.removeSellingPartnerAuthState()
      if (this.authResponseParams.state) await this.$router.replace({ name: 'store:settings:shamp' })
    },
    loadIntegration() {
      this.showInfo = false
      this.loadError = null
      this.loadingIntegration = true
      return this.$http
        .get(`commmon/site/${this.siteID}/integration/amazon/seller`)
        .then(({ data }) => (this.integration = data.data))
        .catch(e => (this.loadError = e))
        .finally(() => (this.loadingIntegration = false))
    },
  },
}
</script>

<style lang="scss" scoped>
td,
th {
  @screen sm {
    @apply px-10 py-1;
  }
}
</style>
