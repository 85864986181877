var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full border overflow-hidden",class:{
    'border-bb-brand-purple': _vm.isActive,
    'rounded-t-md border-1': _vm.rounded === 'top' && _vm.isActive,
    'rounded-b-md': _vm.rounded === 'bottom' && _vm.isActive,

    'border-bb-neutral-100': !_vm.isActive,
    'rounded-t-md border-b-0': _vm.rounded === 'top' && !_vm.isActive,
    'rounded-b-md border-t-0': _vm.border === 'bottom' && _vm.rounded === 'bottom' && !_vm.isActive,
    'rounded-b-md border-1': _vm.border === 'top' && !_vm.isActive && _vm.rounded === 'bottom',

    'rounded-lg border-1': !_vm.isOnboarding,
  }},[_c('div',{staticClass:"h-24",class:[_vm.isActive ? 'bg-neutral-0' : 'bg-white'],on:{"click":function($event){return _vm.$emit('select-channel')}}},[_c('div',{staticClass:"flex justify-between items-center px-4 py-6 cursor-pointer"},[_c('div',{staticClass:"flex gap-4 items-center"},[_c('div',{staticClass:"w-12 flex justify-center"},[_vm._t("icon")],2),_c('div',[_c('h5',{staticClass:"h5"},[_vm._t("card-title")],2),_c('p',{staticClass:"text-sm text-bb-text-secondary"},[_vm._t("card-description")],2)])]),(_vm.hasConnectedAccount)?_c('div',{staticClass:"text-sm bg-bb-success-bg text-bb-success-text rounded-full px-3"},[_vm._v(" Connected ")]):_vm._e(),_c('ic-chevron',{attrs:{"direction":_vm.isActive ? 'down' : 'right'}})],1)]),_c('keep-alive',[(_vm.isActive && _vm.googleAds)?_c('google-ads-integration',{attrs:{"apply-changes":_vm.applyChanges,"is-onboarding":_vm.isOnboarding,"site-details":_vm.siteDetails},on:{"update":function($event){return _vm.onConnectedAccount(true)},"change-applied":_vm.changeApplied,"toggle-modal-loading":_vm.toggleModalLoading}}):_vm._e()],1),_c('keep-alive',[(_vm.isActive && _vm.googleMerchantCenter)?_c('google-merchant-integration',{attrs:{"apply-changes":_vm.applyChanges,"is-onboarding":_vm.isOnboarding,"site-details":_vm.siteDetails},on:{"update":function($event){return _vm.onConnectedAccount(true)},"change-applied":_vm.changeApplied,"toggle-modal-loading":_vm.toggleModalLoading}}):_vm._e()],1),_c('keep-alive',[(_vm.isActive && _vm.amazon)?_c('amazon-integration',{attrs:{"apply-changes":_vm.applyChanges,"is-onboarding":_vm.isOnboarding,"site-details":_vm.siteDetails},on:{"update":function($event){return _vm.onConnectedAccount(true)},"change-applied":_vm.changeApplied,"toggle-modal-loading":_vm.toggleModalLoading}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }