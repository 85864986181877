<template>
  <div class="flex justify-between">
    <div
      v-if="loadingIntegration || loadError"
      class="text-danger"
    />
    <loader-ellipsis
      v-if="loadingIntegration || isIntegrating"
      class="text-primary"
    />
    <div
      v-else-if="isIntegrated"
      class="flex items-center space-x-2"
    >
      <strong>Amazon Advertising id:&nbsp;</strong> {{ integration.advertising_id }}
      <ic-check
        :size="18"
        class="text-success"
      />
    </div>
    <amazon-button
      v-else
      @click="authorise"
    />
  </div>
</template>

<script>
import AmazonButton from '@/components/btn/AmazonButton'
import LoaderEllipsis from '@/components/loader/EllipsisLoader'
import IcCheck from 'vue-material-design-icons/CheckCircle'
import { buildURLQuery } from '@/utils/util'

const LWA_EU_URL = 'https://eu.account.amazon.com/ap/oa'

export default {
  name: 'advertising-integration',
  components: { LoaderEllipsis, AmazonButton, IcCheck },
  inject: ['context'],
  data() {
    return {
      loadingIntegration: false,
      integration: null,
      isIntegrating: false,
      integrationError: null,
      showInfo: false,
      loadError: null,
      siteID: this.context.siteID,
    }
  },
  computed: {
    amazonAuthUrl() {
      const params = {
        client_id: process.env.VUE_APP_LWA_APP_ID,
        scope: 'advertising::campaign_management',
        redirect_uri: `${window.location.origin}/onboarding`,
        response_type: 'code',
      }
      return `${LWA_EU_URL}?${buildURLQuery(params)}`
    },
    isIntegrated() {
      return this.integration?.integrated
    },
  },
  watch: {
    store() {
      this.loadIntegration()
    },
  },
  async mounted() {
    await this.loadIntegration()
    this.finaliseAuth()
  },
  methods: {
    loadIntegration() {
      this.showInfo = false
      this.loadingIntegration = true
      return this.$http
        .get(`common/site/${this.siteID}/integration/amazon/advertiser`)
        .then(({ data }) => (this.integration = data.data))
        .catch(e => (this.loadError = e))
        .finally(() => (this.loadingIntegration = false))
    },
    authorise() {
      window.location = this.amazonAuthUrl
    },
    finaliseAuth() {
      let query = { ...this.$route.query }
      if (!query.scope || !query.code) {
        return
      }

      this.isIntegrating = true
      this.$http
        .post(`common/site/${this.siteID}/integration/amazon/advertiser/authorize`, {
          scope: query.scope,
          auth_code: query.code,
        })
        .then(() => {
          delete query.scope
          delete query.code
          this.$router.replace({ query })
          return this.loadIntegration()
        })
        .catch(e => (this.integrationError = e))
        .finally(() => (this.isIntegrating = false))
    },
  },
}
</script>
