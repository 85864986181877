<template>
  <div
    class="w-full border overflow-hidden"
    :class="{
      'border-bb-brand-purple': isActive,
      'rounded-t-md border-1': rounded === 'top' && isActive,
      'rounded-b-md': rounded === 'bottom' && isActive,

      'border-bb-neutral-100': !isActive,
      'rounded-t-md border-b-0': rounded === 'top' && !isActive,
      'rounded-b-md border-t-0': border === 'bottom' && rounded === 'bottom' && !isActive,
      'rounded-b-md border-1': border === 'top' && !isActive && rounded === 'bottom',

      'rounded-lg border-1': !isOnboarding,
    }"
  >
    <div
      :class="[isActive ? 'bg-neutral-0' : 'bg-white']"
      class="h-24"
      @click="$emit('select-channel')"
    >
      <!--HEADER-->
      <div class="flex justify-between items-center px-4 py-6 cursor-pointer">
        <div class="flex gap-4 items-center">
          <div class="w-12 flex justify-center">
            <slot name="icon" />
          </div>
          <div>
            <h5 class="h5">
              <slot name="card-title" />
            </h5>
            <p class="text-sm text-bb-text-secondary">
              <slot name="card-description" />
            </p>
          </div>
        </div>
        <div
          v-if="hasConnectedAccount"
          class="text-sm bg-bb-success-bg text-bb-success-text rounded-full px-3"
        >
          Connected
        </div>
        <ic-chevron :direction="isActive ? 'down' : 'right'" />
      </div>
    </div>

    <keep-alive>
      <google-ads-integration
        v-if="isActive && googleAds"
        :apply-changes="applyChanges"
        :is-onboarding="isOnboarding"
        :site-details="siteDetails"
        @update="onConnectedAccount(true)"
        @change-applied="changeApplied"
        @toggle-modal-loading="toggleModalLoading"
      />
    </keep-alive>
    <keep-alive>
      <google-merchant-integration
        v-if="isActive && googleMerchantCenter"
        :apply-changes="applyChanges"
        :is-onboarding="isOnboarding"
        :site-details="siteDetails"
        @update="onConnectedAccount(true)"
        @change-applied="changeApplied"
        @toggle-modal-loading="toggleModalLoading"
      />
    </keep-alive>
    <keep-alive>
      <amazon-integration
        v-if="isActive && amazon"
        :apply-changes="applyChanges"
        :is-onboarding="isOnboarding"
        :site-details="siteDetails"
        @update="onConnectedAccount(true)"
        @change-applied="changeApplied"
        @toggle-modal-loading="toggleModalLoading"
      />
    </keep-alive>
  </div>
</template>

<script>
import IcChevron from '@/components/icon/ic-chevron'
import GoogleAdsIntegration from '@/components/onboard-components/GoogleAdsIntegration'
import GoogleMerchantIntegration from '@/components/onboard-components/GoogleMerchantIntegration'
import AmazonIntegration from '@/components/onboard-components/AmazonIntegration'

export default {
  name: 'ChannelCard',
  components: {
    GoogleAdsIntegration,
    GoogleMerchantIntegration,
    AmazonIntegration,
    IcChevron,
  },
  inject: ['context'],
  props: {
    googleAds: {
      type: Boolean,
      default: false,
    },
    googleMerchantCenter: {
      type: Boolean,
      default: false,
    },
    amazon: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    applyChanges: {
      type: Boolean,
      default: false,
    },
    border: {
      type: String,
      default: '',
      validator: val => ['top', 'bottom', ''].includes(val),
    },
    rounded: {
      type: String,
      default: '',
      validator: val => ['top', 'bottom', ''].includes(val),
    },
    isOnboarding: {
      type: Boolean,
      default: false,
    },
    siteDetails: {
      type: Object,
      default: function () {
        return {
          organizationID: '',
          siteID: '',
        }
      },
    },
  },
  data() {
    return {
      hasConnectedAccount: false,
      organizationID: this.isOnboarding ? this.context.organizationID : this.siteDetails.organizationID,
      siteID: this.isOnboarding ? this.context.siteID : this.siteDetails.siteID,
    }
  },
  async mounted() {
    try {
      if (this.googleAds) {
        const resp = await this.$http.get(`common/site/${this.siteID}/integration/google/ads`)
        this.onConnectedAccount(resp.data?.site_integration_data?.google_ads_customer_id !== undefined)
      }
      if (this.googleMerchantCenter) {
        const resp = await this.$http.get(`common/site/${this.siteID}/integration/google/content-api`)
        this.onConnectedAccount(resp.data?.site_integration_data?.google_gmc_customer_id !== undefined)
      }
    } catch (error) {
      this.onConnectedAccount(false)
      console.log(error)
    }
  },
  methods: {
    onConnectedAccount(value) {
      this.hasConnectedAccount = value
      if (value === true) {
        if (this.googleAds) {
          //only Google Ads in required to be connected
          this.$emit('connected')
        }
      }
    },
    changeApplied(status) {
      this.$emit('change-applied', status)
    },
    toggleModalLoading(isLoading) {
      this.$emit('toggle-modal-loading', isLoading)
    },
  },
}
</script>
