import { render, staticRenderFns } from "./GoogleAllowAccessButton.vue?vue&type=template&id=9f06de64&scoped=true"
import script from "./GoogleAllowAccessButton.vue?vue&type=script&lang=js"
export * from "./GoogleAllowAccessButton.vue?vue&type=script&lang=js"
import style0 from "./GoogleAllowAccessButton.vue?vue&type=style&index=0&id=9f06de64&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f06de64",
  null
  
)

export default component.exports