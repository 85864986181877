var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"flex flex-row",class:[
    'google-button',
    'google-font',
    'transition',
    'transform',
    'focus:outline-none',
    'active:scale-95',
    { blue: _vm.blue, 'white px-2 text-google-blue': _vm.white },
  ],attrs:{"type":"button","disabled":_vm.disabled,"name":_vm.name},on:{"click":_vm.click}},[_c('div',{staticClass:"bg-white my-auto h-full flex flex-col justify-center",class:{ 'rounded-full': _vm.white }},[_c('img',{staticClass:"mx-3 my-auto",attrs:{"src":_vm.googleButton,"alt":_vm.name}})]),_c('span',{staticClass:"text-sm mx-3 my-auto"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }