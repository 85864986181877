<template>
  <img
    @click="$emit('click')"
    @mousedown="authButtonPressed"
    @mouseup="authButtonReleased"
    @mouseleave="authButtonReleased"
    ref="auth-btn"
    src="/content/images/lwa-btn.png"
    alt="Login with Amazon"
    style="cursor: pointer; max-width: 156px"
  />
</template>

<script>
export default {
  name: 'amazon-button',
  methods: {
    authButtonPressed() {
      this.$refs['auth-btn'].setAttribute('src', '/content/images/lwa-btn-pressed.png')
    },
    authButtonReleased() {
      this.$refs['auth-btn'].setAttribute('src', '/content/images/lwa-btn.png')
    },
  },
}
</script>
